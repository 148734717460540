// EulaDialog.js

import React, { useState } from 'react';

const EulaDialog = ({ eulaTitle, eulaText, onAgree }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = () => {
    if (isChecked) {
      onAgree();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white p-8 max-w-2xl overflow-hidden">
        <h2 className="text-lg font-bold mb-4" >{eulaTitle}</h2>
        <div className="max-h-120 overflow-auto leading-6 text-black">
          {/* Apply Tailwind CSS classes directly to the div */}
          <div dangerouslySetInnerHTML={{ __html: eulaText }} />
        </div>
        <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
          <input
            id="agreeCheckbox"
            type="checkbox"
            className="mr-2 h-8 w-6"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label htmlFor="agreeCheckbox">Li e concordo com os termos acima</label>
        </div>
        <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
          <button
            className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${
              isChecked ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
            }`}
            style={{ minWidth: '150px' }}
            onClick={handleAgree}
            disabled={!isChecked}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EulaDialog;
